import React, { useState, useEffect } from 'react';
import { Box, Checkbox, VStack, Text, Input, HStack } from '@chakra-ui/react';

const CustomResolution = ({ store }) => {
  const [phoneChecked, setPhoneChecked] = useState(false);
  const [tabletChecked, setTabletChecked] = useState(false);
  const [phoneWidth, setPhoneWidth] = useState('');
  const [phoneHeight, setPhoneHeight] = useState('');
  const [tabletWidth, setTabletWidth] = useState('');
  const [tabletHeight, setTabletHeight] = useState('');

  useEffect(() => {
    if (phoneChecked && phoneWidth && phoneHeight) {
      store.addCustomResolution('phone', phoneWidth, phoneHeight);
    }
  }, [phoneChecked, phoneWidth, phoneHeight, store]);

  useEffect(() => {
    if (tabletChecked && tabletWidth && tabletHeight) {
      store.addCustomResolution('tablet', tabletWidth, tabletHeight);
    }
  }, [tabletChecked, tabletWidth, tabletHeight, store]);

  const handleCheckboxChange = (isChecked, deviceType) => {
    if (deviceType === 'phone') {
      setPhoneChecked(isChecked);
      if (!isChecked) {
        store.removeCustomResolution('phone');
      }
    } else if (deviceType === 'tablet') {
      setTabletChecked(isChecked);
      if (!isChecked) {
        store.removeCustomResolution('tablet');
      }
    }
  };

  const handleInputChange = (e, device, dimension) => {
    const value = e.target.value.replace(/\D/g, ''); // Ensures only numeric input is accepted
    if (device === 'phone') {
      dimension === 'width' ? setPhoneWidth(value) : setPhoneHeight(value);
      if(value === '') {
        store.removeCustomResolution('phone');
      }
    } else {
      dimension === 'width' ? setTabletWidth(value) : setTabletHeight(value);
      if(value === '') {
        store.removeCustomResolution('tablet');
      }
    }
  };

  return (
    <Box pt={4}>
      <Text fontSize="lg" fontWeight="bold">Custom Resolution</Text>

      <Box pl={4}>
        <Text fontSize="md" fontWeight="semibold">Phone</Text>
        <VStack pl={4} align="start">
          <HStack align="center">
            <Checkbox
              isChecked={phoneChecked}
              onChange={(e) => handleCheckboxChange(e.target.checked, 'phone')}
            />
            <Box pl={2}>
              <Input
                placeholder="Width"
                value={phoneWidth}
                onChange={(e) => handleInputChange(e, 'phone', 'width')}
                isDisabled={!phoneChecked}
                size="sm"
                width={20}
              />
            </Box>
            <Text>x</Text>
            <Box pl={2}>
              <Input
                placeholder="Height"
                value={phoneHeight}
                onChange={(e) => handleInputChange(e, 'phone', 'height')}
                isDisabled={!phoneChecked}
                size="sm"
                width={20}
              />
            </Box>
          </HStack>
        </VStack>
      </Box>

      <Box pl={4}>
        <Text fontSize="md" fontWeight="semibold">Tablet</Text>
        <VStack pl={4} align="start">
          <HStack align="center">
            <Checkbox
              isChecked={tabletChecked}
              onChange={(e) => handleCheckboxChange(e.target.checked, 'tablet')}
            />
            <Box pl={2}>
              <Input
                placeholder="Width"
                value={tabletWidth}
                onChange={(e) => handleInputChange(e, 'tablet', 'width')}
                isDisabled={!tabletChecked}
                size="sm"
                width={20}
              />
            </Box>
            <Text>x</Text>
            <Box pl={2}>
              <Input
                placeholder="Height"
                value={tabletHeight}
                onChange={(e) => handleInputChange(e, 'tablet', 'height')}
                isDisabled={!tabletChecked}
                size="sm"
                width={20}
              />
            </Box>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default CustomResolution;
