import React, { useState } from 'react';
import { Box, Checkbox, VStack, Text, Button, ButtonGroup } from '@chakra-ui/react';
import { observer } from 'mobx-react';

const SelectionTreeView = observer(({ store }) => {
  const [orientation, setOrientation] = useState('portrait');

  const handleToggle = (newOrientation) => {
    if (newOrientation !== orientation) { // Only update if orientation has changed
      setOrientation(newOrientation);
      store.clearSelections(); // Clear selections upon changing orientation
    }
  };

  // Adjust groupedSelections call based on the orientation state
  const groupedSelections = store.groupedSelectionsByOrientation(orientation);

  return (
    <VStack align="start" spacing={4}>
      <ButtonGroup isAttached variant="outline">
        <Button
          colorScheme="gray"
          variant={orientation === 'portrait' ? 'solid' : 'outline'}
          onClick={() => handleToggle('portrait')}
        >
          Portrait
        </Button>
        <Button
          colorScheme="gray"
          variant={orientation === 'landscape' ? 'solid' : 'outline'}
          onClick={() => handleToggle('landscape')}
        >
          Landscape
        </Button>
      </ButtonGroup>
      {Object.entries(groupedSelections).map(([storeName, devices]) => (
        <div key={storeName}>
          <Text fontSize="lg" fontWeight="bold">{storeName}</Text>
          {Object.entries(devices).map(([device, models]) => (
            <Box key={device} pl={4}>
              <Text fontSize="md" fontWeight="semibold">{device}</Text>
              <VStack pl={4} align="start">
                {models.map((model, index) => (
                  <Checkbox
                    key={`${model.uniqueIdentifier}-${index}`} // Use unique identifier and index for key
                    isChecked={model.selected}
                    onChange={() => store.toggleSelection(model.uniqueIdentifier)}
                  >
                    {`${model.model} - ${model.resolution}`}
                  </Checkbox>
                ))}
              </VStack>
            </Box>
          ))}
        </div>
      ))}
    </VStack>
  );
});

export default SelectionTreeView;
