// selectionsData.js
export const screenShotSelectionsData = [
    {
      store: 'App Store',
      device: 'Phone',
      model: '6.7"',
      resolution: '1290 x 2796',
      selected: false
    },
    {
      store: 'App Store',
      device: 'Phone',
      model: '6.5"',
      resolution: '1284 x 2778',
      selected: false
    },
    {
      store: 'App Store',
      device: 'Phone',
      model: '5.5"',
      resolution: '1242 x 2208',
      selected: false
    },
    {
      store: 'App Store',
      device: 'Phone',
      model: '6.7"',
      resolution: '2796 x 1290',
      selected: false
    },
    {
      store: 'App Store',
      device: 'Phone',
      model: '6.5"',
      resolution: '2778 x 1284',
      selected: false
    },
    {
      store: 'App Store',
      device: 'Phone',
      model: '5.5"',
      resolution: '2208 x 1242',
      selected: false
    },

    {
      store: 'App Store',
      device: 'Tablet',
      model: 'iPad Pro 12.9"',
      resolution: '2048 x 2732',
      selected: false
    },
    {
      store: 'App Store',
      device: 'Tablet',
      model: 'iPad Pro 12.9"',
      resolution: '2732 x 2048',
      selected: false
    },

    {
      store: 'Play Store',
      device: 'Phone',
      model: '9-16 ratio',
      resolution: '1080 x 1920',
      selected: false
    },
    {
      store: 'Play Store',
      device: 'Phone',
      model: '9-16 ratio',
      resolution: '1620 x 2880',
      selected: false
    },
    {
      store: 'Play Store',
      device: 'Phone',
      model: '16-9 ratio',
      resolution: '1920 x 1080',
      selected: false
    },
    {
      store: 'Play Store',
      device: 'Phone',
      model: '16-9 ratio',
      resolution: '2880 x 1620',
      selected: false
    },
    {
      store: 'Play Store',
      device: 'Tablet',
      model: '9-16 ratio',
      resolution: '2160 x 3840',
      selected: false
    },
    {
      store: 'Play Store',
      device: 'Tablet',
      model: '16-9 ratio',
      resolution: '3840 x 2160',
      selected: false
    }
  ];
  