import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Image as ChakraImage, SimpleGrid, Button, IconButton, VStack, Text } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import uploadIcon from '../assets/icons/images-upload.png'; // Make sure this path is correct
import { MAX_FILES } from '../config'; // Ensure MAX_FILES is correctly imported
import { imageStore } from '../stores/imageStore'; // Ensure this path matches your project structure
import { uuidv4 } from '../utils/uuid'; // Ensure this path matches your project structure

const ImageUploader = ({ store = imageStore }) => { // Default to imageStore if no store prop is provided
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => ({
      id: uuidv4(), // Generate a unique ID for each file
      file,
      preview: URL.createObjectURL(file),
    }));
    setFiles(prevFiles => [...prevFiles, ...newFiles].slice(0, MAX_FILES));
    newFiles.forEach(image => store.addImage({ id: image.id, file: image.file })); // Pass both ID and file
  }, [store]);

  const removeImage = (event, idToRemove) => {
    event.stopPropagation();
    event.preventDefault();
    store.removeImage(idToRemove); // Use ID for removal
    setFiles(currentFiles => currentFiles.filter(file => file.id !== idToRemove));
  };

  useEffect(() => {
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpg, image/jpeg, image/png, image/gif',
    maxFiles: MAX_FILES,
  });

  const handleRemoveAllImages = () => {
    // Clear local state if applicable
    setFiles([]);

    // Clear images from the ImageStore
    store.clearImages();
  };

  return (
    <Box {...getRootProps()} p={5} border="2px dashed gray" textAlign="center">
      <input {...getInputProps()} />
      {files.length === 0 && (
        <VStack spacing={3}>
          <Text>Drop or Click to Upload up to {MAX_FILES} images</Text>
          <ChakraImage boxSize="100px" src={uploadIcon} alt="Upload icon" />
          <Text fontSize="sm">Supported formats: JPG, JPEG, PNG, GIF</Text>
        </VStack>
      )}
      <SimpleGrid columns={3} spacing={4} mt={4}>
        {files.map((file, index) => (
          <Box key={file.id} position="relative" width="100%" pt="100%" overflow="hidden"> {/* Maintain square aspect ratio */}
            <ChakraImage
              src={file.preview}
              alt={`preview ${index}`}
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              objectFit="cover"
            />
            <IconButton
              aria-label="Remove image"
              icon={<CloseIcon />}
              size="sm"
              colorScheme="red"
              position="absolute"
              top="1"
              right="1"
              zIndex="1"
              onClick={(event) => removeImage(event, file.id)}
            />
          </Box>
        ))}
      </SimpleGrid>
      {files.length > 0 && (
         <Button mt={4} onClick={handleRemoveAllImages}>Remove All Images</Button>     
      )}
    </Box>
  );
};

export default ImageUploader;
