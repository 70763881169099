import React, { useEffect } from 'react';
import { Image, Flex, Box, Heading, Button, Text, Link as ChakraLink } from '@chakra-ui/react';
import ImageUploader from './components/imageUploader';
import SelectionTreeView from './components/selectionTreeView';
import CustomResolution from './components/customResolution';
import EasySteps from './components/easySteps';
import { observer } from 'mobx-react';
import { imageStore } from './stores/imageStore';
import { processAndDownloadImages } from './utils/imageUtils';
import bmcIcon from '../../assets/images/bmc-icon.png'; // Ensure the path is correct
import { sendPageView, sendEvent } from '../../common/analytics';
import { BMC_LINK } from '../../common/config';

const Resizer = observer(() => {

  useEffect(() => {
    sendPageView('/resizer'); // Send page view whenever the Resizer component mounts
  }, []);

  const handleDownload = () => {
    processAndDownloadImages(imageStore.images, imageStore.resizeOutputs);
    sendEvent('Image', 'Upload', imageStore.images.length.toString());
  };

  const isButtonEnabled = imageStore.images.length > 0 && 
  (
    imageStore.resizeOutputs.length > 0
    //imageStore.selections.some(selection => selection.selected) 
  );

  return (    
    <>      
      <Flex direction={{ base: 'column', md: 'row' }} align="flex-start" justify="space-between">
        <Box w={{ base: '100%', md: '60%' }} >
          <Box p={4} borderWidth="1px" borderRadius="lg" mb={{ base: 4, md: 0 }}>
            <ImageUploader store={imageStore} />          
          </Box>
          <Box color="gray.600" textAlign="center" pt={5} mb={4}>
            <Heading as="h2" fontSize="3xl" fontWeight="bold" mb={2}>Resizer</Heading>
            <Text color="gray.500" fontSize="2xl">Resize your App Store and Google Play Store images</Text>
          </Box>
          <Box color="gray.600" textAlign="center" pt={5} mb={4}>
            <EasySteps />
          </Box>
          <Box color="gray.700" textAlign="center" fontWeight="bold" pt={5} mb={4}>
            <Text fontSize="2xl">Help us keep our exciting tools free!</Text>            
            <Box mt={5}></Box>
            <Button 
              as={ChakraLink} 
              mr={4} 
              href={BMC_LINK} 
              colorScheme="yellow" 
              isExternal
              onClick={() => {
                sendEvent('Donate', 'Resizer', 'clicked');
                // Here you can add your tracking code
              }}
              >
                  <Image src={bmcIcon} boxSize="20px" mr={2} alt="Donate Icon" />
                  Donate
            </Button>     
          </Box>
        </Box>
        <Box w={{ base: '100%', md: '35%' }} p={4} borderWidth="0px" borderRadius="lg">        
          <SelectionTreeView store={imageStore} />          
          <CustomResolution store={imageStore} />           
          <Button onClick={handleDownload} mt={4} isDisabled={!isButtonEnabled}>
            Download Resized Images
          </Button>
        </Box>
      </Flex>    
    </>
  );
});

export default Resizer;
