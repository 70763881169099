import React from 'react';
import { Box, Flex, Text, Icon, VStack } from '@chakra-ui/react';
import { MdArrowForward } from 'react-icons/md';

const EasySteps = () => {
  const steps = ['UPLOAD', 'SELECT RESOLUTION', 'DOWNLOAD'];

  return (
    <VStack  spacing={4}>
      <Flex align="center">
        {steps.map((step, index) => (
          <Flex key={index} align="center">
            <Box
              p={2}
              borderRadius="md"                            
              boxShadow="sm"              
            >
              <Text fontSize="1xl" fontWeight="bold" >
                {step}
              </Text>
            </Box>
            {index < steps.length - 1 && (
              <Icon as={MdArrowForward} w={10} h={10} mx={2} />
            )}
          </Flex>
        ))}
      </Flex>
    </VStack>
  );
};

export default EasySteps;
