import React, { useEffect } from 'react';
import { Text, ChakraProvider, Container, Flex, Heading, Image, HStack, Button, Divider, Box, Link as ChakraLink } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Resizer from './modules/resizer/resizer';
import logo from './assets/images/Logo.png'; // Adjust the path as necessary
import bmcIcon from './assets/images/bmc-icon.png'; // Ensure the path is correct
import { initGA, sendEvent } from './common/analytics';
import { BMC_LINK } from './common/config';

const App = () => {
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    initGA(); // Initialize Google Analytics when the app loads
  }, []);

  return (
    <ChakraProvider>
      <Router>
        <Container maxW="container.xl" p={5}>
          {/* Menu */}
          <Box position="fixed" top="1" left="0" right="0" bg="white" zIndex="999" boxShadow="md">
            <Flex align="center" justify="space-between">
              <HStack spacing={4}>
                <Link to="/resizer">
                <Image src={logo} boxSize="50px" alt="Pixel Wizard Logo" ml={4} />                  
                </Link>
                <Link to="/resizer" style={{ textDecoration: 'none' }}>                
                  <Heading as="h1" fontWeight="normal">
                    <Text as="span" color="gray.400">pixel</Text>                      
                    <Text as="span" color="gray.600" fontWeight="bold">WIZARD</Text>
                  </Heading>
                </Link>
              </HStack>
              <HStack spacing={4}>       
                <ChakraLink as={Link} to="/resizer" fontWeight="semibold" _hover={{ color: 'blue.500' }}>
                  Resizer
                </ChakraLink>                       
                {/* Placeholder for Donate link */}             
                <Button 
                  as={ChakraLink} 
                  mr={4} 
                  href={ BMC_LINK }
                  variant='outline' 
                  colorScheme="yellow" 
                  isExternal
                  onClick={() => {
                    sendEvent('Donate', 'Menu', 'clicked');
                    // Here you can add your tracking code
                  }}
                  >
                  <Image src={bmcIcon} boxSize="20px" mr={2} alt="Donate Icon" />
                  Donate
                </Button>              
              </HStack>              
            </Flex>
            <Divider borderColor="gray.200" />
          </Box>

          {/* Placeholder for padding to ensure content is not overlapped */}
          <Box h="30px" />

          {/* Content */}
          <Box pt={4}>
            <Routes>
              <Route path="/" element={<Navigate replace to="/resizer" />} />
              <Route path="/resizer" element={<Resizer />} />
              {/* Define more routes as needed */}
            </Routes>
          </Box>

          {/* Footer */}
          <Box mt={2} textAlign="center">
            <Text fontSize="sm" color="gray.500">
              Disclaimer: Uploaded images are not stored or processed on our server.
              Usage is monitored for internal purposes.
            </Text>
            <Text fontSize="sm" color="gray.500">
              Copyright &copy; IPE Solutions LLC @{currentYear}. All rights reserved.
            </Text>
          </Box>
        </Container>
      </Router>     
    </ChakraProvider>
  );
};

export default App;
