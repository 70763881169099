import { makeAutoObservable } from 'mobx';
import { screenShotSelectionsData } from '../data/screenShotGeneration';
import { MAX_FILES } from '../config';
import { uuidv4 } from '../utils/uuid';

class ImageStore {
  images = [];
  selections = screenShotSelectionsData;
  resizeOutputs = [];

  constructor() {
    makeAutoObservable(this);
  }

  addImage(imageFile) {
    if (this.images.length >= MAX_FILES) {
      throw new Error(`Cannot add more than ${MAX_FILES} images.`);
    }
    this.images.push({
      id: uuidv4(),
      file: imageFile,
    });
  }

  removeImage(imageId) {
    this.images = this.images.filter(image => image.id !== imageId);
  }

  clearImages() {
    this.images = [];
  }

  toggleSelection(uniqueIdentifier) {
    const selectionIndex = this.selections.findIndex(selection => 
      `${selection.store}-${selection.device}-${selection.model}-${selection.resolution}` === uniqueIdentifier
    );
    if (selectionIndex !== -1) {
      this.selections[selectionIndex].selected = !this.selections[selectionIndex].selected;
      this.preserveAndUpdateResizeOutputs();
    }
  }

  clearSelections() {
    this.selections.forEach(selection => selection.selected = false);
    this.preserveAndUpdateResizeOutputs();
  }

  addCustomResolution(device, width, height) {
    this.removeCustomResolution(device);
    this.resizeOutputs.push({ store: 'custom', device, model: 'custom', width, height, id: uuidv4() });
  }

  removeCustomResolution(device) {
    this.resizeOutputs = this.resizeOutputs.filter(output => !(output.device === device && output.store === 'custom'));
  }
  
  preserveAndUpdateResizeOutputs() {
    const customResolutions = this.resizeOutputs.filter(output => output.store === 'custom');
    this.resizeOutputs = [...customResolutions];

    this.selections.forEach(selection => {
      if (selection.selected) {
        const [width, height] = selection.resolution.split(' x ').map(Number);
        this.resizeOutputs.push({
          store: selection.store,
          device: selection.device,
          model: selection.model,
          width,
          height,
          id: uuidv4() // Ensuring unique IDs for outputs as well
        });
      }
    });
  }

  clearResizeOutput() {
    this.resizeOutputs = [];
  }

  getSelectedResolutions() {
    const selectedResolutions = {};
    this.selections.forEach(selection => {
      if (selection.selected) {
        if (!selectedResolutions[selection.store]) {
          selectedResolutions[selection.store] = {};
        }
        if (!selectedResolutions[selection.store][selection.device]) {
          selectedResolutions[selection.store][selection.device] = {};
        }
        selectedResolutions[selection.store][selection.device][selection.model] = selection.resolution;
      }
    });
    return selectedResolutions;
  }

  groupedSelectionsByOrientation(orientation) {
    const isPortrait = orientation === 'portrait';
    return this.selections.filter(({ resolution }) => {
      const [width, height] = resolution.split(' x ').map(Number);
      return isPortrait ? height > width : width > height;
    }).reduce((acc, selection) => {
      const uniqueIdentifier = `${selection.store}-${selection.device}-${selection.model}-${selection.resolution}`;
      if (!acc[selection.store]) acc[selection.store] = {};
      if (!acc[selection.store][selection.device]) acc[selection.store][selection.device] = [];
      acc[selection.store][selection.device].push({ ...selection, uniqueIdentifier });
      return acc;
    }, {});
  }
}

export const imageStore = new ImageStore();
