import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-Y24V41GG22');
};

export const sendPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

export const sendEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
