import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { sendEvent } from '../../../common/analytics';

const resizeImage = (image, width, height) => {
  return new Promise((resolve, reject) => {
    // Check if the image is a File or Blob
    
    if (!(image instanceof Blob)) {
      reject(new Error("The provided value is not a Blob or File."));
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = () => {
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(blob => {
        resolve(blob);
      }, image.type || 'image/jpeg');
    };
    img.onerror = error => {
      reject(new Error(`There was a problem loading the image: ${error}`));
    };
  });
};

export const processAndDownloadImages = async (images, resizeOutputs) => {
  const zip = new JSZip();

  if (!resizeOutputs || resizeOutputs.length === 0) {
    console.error("ResizeOutput is undefined or empty");
    return;
  }

  for (const output of resizeOutputs) {
    const { store, device, model, width, height } = output;

    for (const { file } of images) { // Destructure to directly access the file object
      try {
        const resizedImageBlob = await resizeImage(file.file, width, height);
        const modelName = model.replace(/"/g, ''); // Cleaning model name for file naming
        const fileName = `${modelName}_${width}x${height}_${file.file.name}`;
        zip.folder(store)
          .folder(device)
          .file(fileName, resizedImageBlob);
      } catch (error) {
        console.error("Error resizing image:", error);
      }
    }
  }

  // Generate the ZIP file and trigger download
  zip.generateAsync({ type: "blob" })
    .then(blob => {
      saveAs(blob, "TransformedImages.zip");
      sendEvent('Image', 'Download', zip.length.toString());
    })
    .catch(error => {
      console.error("Error generating zip:", error);
    });
};
